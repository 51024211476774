<template>
  <div>
    <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol" :rules="rules" ref="form">
      <a-form-model-item label="设备类型Id">
        <a-input :disabled="true" v-model="form.deviceTypeId" />
      </a-form-model-item>
      <a-form-model-item label="负载设备类型" v-if="loadedDeviceTypeList" prop="loadDeviceTypeId">
        <a-select v-model="form.loadDeviceTypeId" showSearch placeholder="请选择负载设备类型">
          <a-select-option v-for="loaded in loadedDeviceTypeList" :key="loaded.loadedDeviceTypeId.toString()">{{
            loaded.name
          }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="设备类型" prop="name">
        <a-input v-model="form.name" />
      </a-form-model-item>
      <a-form-model-item label="英文名" prop="enName">
        <a-input v-model="form.enName" />
      </a-form-model-item>
      <!-- <a-form-model-item label="备注">
        <a-textarea
          v-model="form.remark"
          placeholder="服务商备注"
          :auto-size="{ minRows: 15, maxRows: 20 }"
          size="large"
        />
        </a-form-model-item> -->
      <a-form-item class="group-btn">
        <a-button type="primary" @click="updateBrand">确定</a-button>
        <a-button class="creator-btn" @click="cancel">返回</a-button>
      </a-form-item>
    </a-form-model>
  </div>
</template>

<script>
export default {
  name: 'DeviceTypeEdit',
  components: {},
  data() {
    return {
      loadedDeviceTypeList: undefined,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      rules: {
        name: [{ required: true, message: '设备类型 不能为空', trigger: 'blur' }],
        enName: [{ required: true, message: '英文名 不能为空', trigger: 'blur' }],
        loadDeviceTypeId: [{ required: true, message: '负载设备类型 不能为空', trigger: 'change' }],
      },
      form: {},
    };
  },
  mounted() {
    this.form = this.$route.params.deviceType;
    this.loadedDeviceType();
    if (!this.form) {
      this.$router.back();
    }
  },
  methods: {
    async loadedDeviceType() {
      const response = await this.$apiManager.deviceType.loadedDeviceType({ unpaged: true });
      this.loadedDeviceTypeList = response.data.elements;
    },
    cancel() {
      this.$router.back();
    },
    updateBrand() {
      this.$refs.form.validate((relsValid) => {
        if (relsValid) {
          this.$apiManager.deviceType.updateDeviceTypes(this.form).then(() => {
            this.$message.success('修改成功');
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.creator-btn {
  margin-left: 5%;
}
.device-type-operations {
  display: flex;
  .add-btn {
    margin: 10px 0px;
    margin-left: 3%;
  }
  .deviceType-table {
    margin-top: 2%;
  }
}
.group-btn {
  margin-left: 16.7%;
}
</style>
